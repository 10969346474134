import _sha from "./sha";
import _sha2 from "./sha1";
import _sha3 from "./sha224";
import _sha4 from "./sha256";
import _sha5 from "./sha384";
import _sha6 from "./sha512";
var exports = {};
var module = {
  exports: exports
};

var exports = module.exports = function SHA(algorithm) {
  algorithm = algorithm.toLowerCase();
  var Algorithm = exports[algorithm];
  if (!Algorithm) throw new Error(algorithm + " is not supported (we accept pull requests)");
  return new Algorithm();
};

exports.sha = _sha;
exports.sha1 = _sha2;
exports.sha224 = _sha3;
exports.sha256 = _sha4;
exports.sha384 = _sha5;
exports.sha512 = _sha6;
export default module.exports;
export const sha = module.exports.sha,
      sha1 = module.exports.sha1,
      sha224 = module.exports.sha224,
      sha256 = module.exports.sha256,
      sha384 = module.exports.sha384,
      sha512 = module.exports.sha512;