import _inherits from "inherits";
import _sha from "./sha512";
import _hash from "./hash";
import _safeBuffer from "safe-buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var inherits = _inherits;
var SHA512 = _sha;
var Hash = _hash;
var Buffer = _safeBuffer.Buffer;
var W = new Array(160);

function Sha384() {
  this.init();
  (this || _global)._w = W;
  Hash.call(this || _global, 128, 112);
}

inherits(Sha384, SHA512);

Sha384.prototype.init = function () {
  (this || _global)._ah = 3418070365;
  (this || _global)._bh = 1654270250;
  (this || _global)._ch = 2438529370;
  (this || _global)._dh = 355462360;
  (this || _global)._eh = 1731405415;
  (this || _global)._fh = 2394180231;
  (this || _global)._gh = 3675008525;
  (this || _global)._hh = 1203062813;
  (this || _global)._al = 3238371032;
  (this || _global)._bl = 914150663;
  (this || _global)._cl = 812702999;
  (this || _global)._dl = 4144912697;
  (this || _global)._el = 4290775857;
  (this || _global)._fl = 1750603025;
  (this || _global)._gl = 1694076839;
  (this || _global)._hl = 3204075428;
  return this || _global;
};

Sha384.prototype._hash = function () {
  var H = Buffer.allocUnsafe(48);

  function writeInt64BE(h, l, offset) {
    H.writeInt32BE(h, offset);
    H.writeInt32BE(l, offset + 4);
  }

  writeInt64BE((this || _global)._ah, (this || _global)._al, 0);
  writeInt64BE((this || _global)._bh, (this || _global)._bl, 8);
  writeInt64BE((this || _global)._ch, (this || _global)._cl, 16);
  writeInt64BE((this || _global)._dh, (this || _global)._dl, 24);
  writeInt64BE((this || _global)._eh, (this || _global)._el, 32);
  writeInt64BE((this || _global)._fh, (this || _global)._fl, 40);
  return H;
};

exports = Sha384;
export default exports;