import _inherits from "inherits";
import _sha from "./sha256";
import _hash from "./hash";
import _safeBuffer from "safe-buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * A JavaScript implementation of the Secure Hash Algorithm, SHA-256, as defined
 * in FIPS 180-2
 * Version 2.2-beta Copyright Angel Marin, Paul Johnston 2000 - 2009.
 * Other contributors: Greg Holt, Andrew Kepert, Ydnar, Lostinet
 *
 */
var inherits = _inherits;
var Sha256 = _sha;
var Hash = _hash;
var Buffer = _safeBuffer.Buffer;
var W = new Array(64);

function Sha224() {
  this.init();
  (this || _global)._w = W; // new Array(64)

  Hash.call(this || _global, 64, 56);
}

inherits(Sha224, Sha256);

Sha224.prototype.init = function () {
  (this || _global)._a = 3238371032;
  (this || _global)._b = 914150663;
  (this || _global)._c = 812702999;
  (this || _global)._d = 4144912697;
  (this || _global)._e = 4290775857;
  (this || _global)._f = 1750603025;
  (this || _global)._g = 1694076839;
  (this || _global)._h = 3204075428;
  return this || _global;
};

Sha224.prototype._hash = function () {
  var H = Buffer.allocUnsafe(28);
  H.writeInt32BE((this || _global)._a, 0);
  H.writeInt32BE((this || _global)._b, 4);
  H.writeInt32BE((this || _global)._c, 8);
  H.writeInt32BE((this || _global)._d, 12);
  H.writeInt32BE((this || _global)._e, 16);
  H.writeInt32BE((this || _global)._f, 20);
  H.writeInt32BE((this || _global)._g, 24);
  return H;
};

exports = Sha224;
export default exports;